import "./style.scss"
import {gsap} from "gsap"

console.log(gsap)
window.onload=()=>{
// "#rect"の参照を取得し、アニメーションを適用
gsap.to("#rect", {
  duration: 2, // 右側に2秒かけて移動するモーションを指定する
  x: 800,
  rotate: 360,
  repeat: -1,
});
gsap.to("#mainVisualText01", {
  duration: 0.6, 
  x: -140,
  y: 10,
  ease: "power4.out",
  autoAlpha: 1,
  scale: 2,
  delay: 2,
});
gsap.to("#mainVisualText02", {
  duration: 0.6, 
  x: -140,
  y: 10,
  ease: "power4.out",
  autoAlpha: 1,
  scale: 2,
  delay: 2.2,
});
gsap.to("#mainVisualText03", {
  duration: 0.6, 
  x: -140,
  y: 10,
  ease: "power4.out",
  autoAlpha: 1,
  scale: 2,
  delay: 2.4,
});
gsap.to("#mainVisualImage", {
  duration: 3.5,
  ease: "power4.inOut",
  autoAlpha: 1,
  blur: 0,
});
handleMenu('menu_about', 'open_menu_about')
handleMenu('menu_service', 'open_menu_service')
handleMenu('menu_recruit', 'open_menu_recruit')
// recruitButton()
// toggleButton()
handleNavigation('navigation_button','navigation_list')
staffVoice('staff01','staff01_detail','staff01_close')
staffVoice('staff02','staff02_detail','staff02_close')
staffVoice('staff03','staff03_detail','staff03_close')
handleToggleDisabledContactForm()
handleValidation()
};


//コンタクトフォームの挙動
function handleToggleDisabledContactForm(){
  const requireName = document.getElementById('require_name')
  if(!requireName) return
  const requireEmail = document.getElementById('require_email')
  if(!requireEmail) return
  const requireDetail = document.getElementById('require_detail')
  if(!requireDetail) return
  const requireContactTypes = document.getElementsByClassName('require_contact_type')

  function _handleInputChecking(){
    if (isInputed(requireName) && isInputed(requireEmail) && isInputed(requireDetail)){
      Array.from(requireContactTypes).forEach(function(v){
        if(v.checked){
          document.getElementById('submit_button').classList.remove('is-disabled')
        }
      })
    }
  }
  requireName.addEventListener('keyup',_handleInputChecking)
  requireEmail.addEventListener('keyup',_handleInputChecking)
  requireDetail.addEventListener('keyup',_handleInputChecking)
  Array.from(requireContactTypes).forEach(function(v){
    v.addEventListener('change',_handleInputChecking)
  })
}
function isInputed(element){
  if(element.value){
    return true
  }
  return false
}
//コンタクトフォームでsubmit_buttonを押した時にバリデーションが表示される
function handleValidation(){
  const submitButton = document.getElementById('submit_button')
  if(!submitButton) return 



  submitButton.addEventListener('click' , function(e){

    let error = false

    const requireName = document.getElementById('require_name')
    if(!requireName) return
    if(!isInputed(requireName)){
      document.getElementById('error_require_name').classList.remove('is-hide')
      error = true
    } else {
      document.getElementById('error_require_name').classList.add('is-hide')
    }


    const requireEmail = document.getElementById('require_email')
    if(!requireEmail) return
    if(!isInputed(requireEmail)){
      document.getElementById('error_require_email').classList.remove('is-hide')
      error = true
    } else {
      document.getElementById('error_require_email').classList.add('is-hide')
    }


    const requireDetail = document.getElementById('require_detail')
    if(!requireDetail) return
    if(!isInputed(requireDetail)){
      document.getElementById('error_require_detail').classList.remove('is-hide')
      error = true
    } else {
      document.getElementById('error_require_detail').classList.add('is-hide')
    }

    const requireContactTypes = document.getElementsByClassName('require_contact_type') 
    let tmpChecked = []
    Array.from(requireContactTypes).forEach(function(v){
      if(!v.checked){
        tmpChecked.push(true)
      }
    })
    if (tmpChecked.length != 3)  {
      document.getElementById('error_require_contact_type').classList.remove('is-hide')
      error = true
    } else {
      document.getElementById('error_require_contact_type').classList.add('is-hide')
    }

    if (error) {
      window.scroll(0)
      e.preventDefault()
      e.stopPropagation()
    }

  })
}

//ヘッダー部分のメニュー
function handleMenu(buttonId, openMenuId){
  const menuAbout = document.getElementById(buttonId);
  const openMenuAbout = document.getElementById(openMenuId);

  if (!menuAbout) return
  if (!openMenuAbout) return

  let timeId = null;

  menuAbout.addEventListener('mouseenter', function(){
    // [期待すること] #open_menu_aboutが表示される
    // [実現方法] is-hide-menuButtonからis-show-menuButtonに変わる
    openMenuAbout.classList.remove('is-hide')
    openMenuAbout.classList.add('is-show')
  });
  menuAbout.addEventListener('mouseleave', function(){
    // [期待すること] #open_menu_aboutが非表示になる
    //              下にあるメニューにホバーしたときはそのまま表示されたい。
    // [実現方法] is-show-menuButtonからis-hide-menuButtonに変わる
    //           ただし、非表示になるまで300ミリ秒遅延する
    timeId = setTimeout(function() {
      openMenuAbout.classList.remove('is-show')
      openMenuAbout.classList.add('is-hide')
    }, 300)
  });

  openMenuAbout.addEventListener('mouseenter', function(event){
    // [期待すること] #open_menu_aboutが表示されたままになる
    // [実現方法] is-show-menuAreaを取得した状態
    event.preventDefault();

    if (timeId) {
      clearTimeout(timeId);
      timeId = null;
    }
  });
  openMenuAbout.addEventListener('mouseleave', function(event){
    // [期待すること] open_menu_aboutが非表示になる
    // 実現方法 is-hide-menuAreaを取得して、is-show-menuAreaが消える
    event.preventDefault();
    openMenuAbout.classList.remove('is-show')
    openMenuAbout.classList.add('is-hide')
  })
};

// function recruitButton(){
//   const openButton = document.querySelector('.staffVoice_openButton')
//   const closeButton = document.querySelector('.is-close')

//   openButton.addEventListener('click', function(){
//     openButton.classList.add('is-close')
//   });
//   closeButton.addEventListener('click', function(){
//     openButton.classList.remove('is-close')
//   });
// };


// [期待すること] .header_navigation-sp_list_item_linkをクリックしたら.header_navigation-sp_list_item_subが表示される
// [実現方法] 

// function toggleButton(){
//   const openToggleButton = document.querySelector('.header_navigation-sp_list_item_link')

//   openToggleButton.addEventListener('click', function(){
//     alert('12341234')
//   })
// }
function handleNavigation(navigationButtonId,navigationListId){
  const navigationButton = document.getElementById(navigationButtonId)
  const navigationList = document.getElementById(navigationListId)
  const navigationOpenButton = document.querySelector('.header_navigation-sp_list_item_button')
  // [期待すること] menuが開閉する
  // [実現方法]
  // navigation_buttonがクリックされた時、navigation_listが表示される。
  // 　navigation_listにis-showが追加される、is-hideが削除される
  //
  // navigation_listが表示された状態でnavigation_buttonがクリックされた時、navigation_listが非表示になる
  //   navigation_listにis-showが追加されて、is-hideが削除されている時、クリックした場合、navigation_listにis-hideが追加されis-showが削除される
  navigationButton.addEventListener('click', function(event){
    navigationList.classList.toggle('is-hide')
    navigationButton.classList.toggle('is-open')
  })
  const navigationAbout = document.getElementById('about')
  const subNavigationAbout = document.getElementById('sub_navigation_about')
  function _resetBlue() {
    const blues = document.getElementsByClassName("is-blue")
    if (!blues) {
      return
    }
    Array.from(blues).forEach(function(e){
      e.classList.remove("is-blue")
    })
  }
  navigationAbout.addEventListener('click', function(){
    _resetBlue()
    event.target.parentNode.parentNode.classList.add('is-blue')
    subNavigationAbout.classList.toggle('is-hide')
    navigationAbout.classList.toggle('is-active')
  })
  const navigationService = document.getElementById('service')
  const subNavigationService = document.getElementById('sub_navigation_service')
  navigationService.addEventListener('click', function(event){
    _resetBlue()
    event.target.parentNode.parentNode.classList.add('is-blue')
    subNavigationService.classList.toggle('is-hide')
    navigationService.classList.toggle('is-active')
  })
  const navigationRecruit = document.getElementById('recruit')
  const subNavigationRecruit = document.getElementById('sub_navigation_recruit')
  navigationRecruit.addEventListener('click', function(event){
    _resetBlue()
    event.target.parentNode.parentNode.classList.add('is-blue')
    subNavigationRecruit.classList.toggle('is-hide')
    navigationRecruit.classList.toggle('is-active')
  })
}

function staffVoice(staffVoiceImageId,staffVoiceDetailId,staffVoiceCloseButtonId){
  const staffVoiceImage = document.getElementById(staffVoiceImageId)
  if(!staffVoiceImage){
    return
  }
    
  const staffVoiceDetail = document.getElementById(staffVoiceDetailId)
  const staffVoiceCloseButton = document.getElementById(staffVoiceCloseButtonId)

  staffVoiceImage.addEventListener('click', function(){
    staffVoiceDetail.classList.toggle('is-hide')
    staffVoiceImage.classList.toggle('is-open')
  })

  staffVoiceCloseButton.addEventListener('click', function(){
    staffVoiceDetail.classList.add('is-hide')
    staffVoiceImage.classList.remove('is-open')
  })
}